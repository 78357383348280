import React, { Component } from "react";
// import { a } from 'react-router-dom';
import { FiX , 
    // FiMenu 
} from "react-icons/fi";
import Nav from "../navigation/Nav";


class Header extends Component{
    constructor(props) {
        super(props);
        this.menuTrigger = this.menuTrigger.bind(this);
        this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
       //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
        window.addEventListener('load', function() {
            console.log('All assets are loaded')
        })
    }

    menuTrigger() {
        document.querySelector('.header-wrapper').classList.toggle('menu-open')
    }

    CLoseMenuTrigger() {
        document.querySelector('.header-wrapper').classList.remove('menu-open');
    }


    render() {
        var elements = document.querySelectorAll('.has-droupdown > a');
        for(var i in elements) {
            if(elements.hasOwnProperty(i)) {
                elements[i].onclick = function() {
                    this.parentElement.querySelector('.submenu').classList.toggle("active");
                    this.classList.toggle("open");
                }
            }
        }

        window.addEventListener('scroll', function() {
            var value = window.scrollY;
            if (value > 200) {
                document.querySelector('.header--fixed').classList.add('sticky');
                document.querySelector('#logo-image').src = '/assets/images/logo/bytecoding.png';
                document.documentElement.style.setProperty("--menuIconColor", '#000');
            } else {
                document.querySelector('.header--fixed').classList.remove('sticky');
                document.querySelector('#logo-image').src = '/assets/images/logo/bytecoding-light.png';
                document.documentElement.style.setProperty("--menuIconColor", '#C6C9D8');

            }
        });

        const { 
            color='default-color'
        } = this.props;
        
        return(
            <header className={`header-area formobile-menu header--fixed header--transparent ${color}`}>
                <div className="header-wrapper" id="header-wrapper">
                    <div className="header-left">
                        <div className="logo">
                            <a href="/">
                                <img src='/assets/images/logo/bytecoding-light.png' alt="Byte Coding Agency Logo" id="logo-image" />
                            </a>
                        </div>
                    </div>
                    <div className="header-right">
                        <nav className="mainmenunav d-lg-block">
                            <ul className="mainmenu">
                                <li><a href="#home">Home</a></li>
                                <li><a href="#about" >About</a></li>
                                <li><a href="#services" >Services</a></li>
                                {/* <li><a href="#works" >Our Works</a></li> */}
                                {/* <li><a href="#reviews" >Reviews</a></li> */}
                                {/* <li><a href="#contact" >Contact</a></li> */}
                            </ul>
                        </nav>
                        <div className="header-btn">
                            <a className="rn-btn btn-solid" href="#contact">
                                <span>Start Your project</span>
                            </a>
                        </div>
                        {/* Start Humberger Menu  */}
                        <div className="humberger-menu d-block d-lg-none pl--20">
                            {/* <span onClick={this.menuTrigger} className="menutrigger text-white"><FiMenu /></span> */}
                            <Nav />
                        </div>
                        {/* End Humberger Menu  */}
                        <div className="close-menu d-block d-lg-none">
                            <span onClick={this.CLoseMenuTrigger} className="closeTrigger"><FiX /></span>
                        </div>
                    </div>
                </div>
            </header>
        )
    }
}
export default Header;