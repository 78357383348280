import React, { Component } from "react";

class SliderOne extends Component {
  render() {
    return (
        <div className="slider-activation">
            {/* Start Single Slide */}
            <div className="slide slide-style-1 slider-fixed--height d-flex align-items-center bg_image bg_image--1" data-black-overlay="6">
                <div className="container position-relative margin-top-minus">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="inner inner-header">
                                <h1 className="title theme-gradient">Byte <br /> coding </h1>
                            </div>
                        </div>
                        <div className="col-lg-12 mt-sm-2">
                            <div className="subtext-container">
                                <div className="">
                                    <h2 className="title heading-white text-left d-block">Bringing Brands <br/>To Digital Life</h2>
                                    <a className="rn-button-style--2 btn-solid mt-lg-3 mt-md-3 mt-sm-3 mt-xs-1" href="#services"><span>View what we do</span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
            {/* End Single Slide */}
        </div>
    );
  }
}
export default SliderOne;