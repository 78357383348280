import React, { Component } from "react";

class About extends Component{
    render(){
        let title = 'WHO';
        
        return(
            <div className="about-wrapper">
                <div className="container">
                    <div className="row row--35 align-items-center">
                        <div className="col-lg-5">
                            <div className="thumbnail">
                                <img className="w-100" src="/assets/images/about/agency-computer-2.jpg" alt="About Images"/>
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <div className="about-inner inner">
                                <div className="section-title">
                                    <h2 className="title">{title}</h2>
                                    <h3 className="title ln-1">A CREATIVE WEB DEVELOPMENT AND DESIGN AGENCY BASED IN NYC</h3>
                                    <p className="description">We are <strong>ByteCoding</strong>, a full-service Creative Digital Agency who empower ambitious businesses. Our experts specialise in <strong>e-commerce</strong> Websites, <strong>Branding</strong> and <strong>Digital Marketing</strong>.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default About;