import React, { Component, Fragment } from "react";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "./component/header/Header";
import Footer from "./component/footer/Footer";

import SliderOne from "./component/slider/SliderOne";
// import ServiceTwo from "./elements/service/ServiceTwo";
import CounterOne from "./elements/counters/CounterOne";
// import Testimonial from "./elements/Testimonial";
import About from "./component/HomeLayout/homeOne/About";
// import Portfolio from "./component/HomeLayout/homeOne/Portfolio";
// import BlogContent from "./elements/blog/BlogContent";
// import BrandTwo from "./elements/BrandTwo";
// import Helmet from "./component/common/Helmet";
// import HomeParticles from "./home/HomeParticles";
// import MainDemo from "./home/MainDemo";
import ServiceList from "./elements/service/ServiceList";
import Contact from "./elements/contact/ContactTwo";
import { ToastContainer } from 'react-toastify';

class App extends Component {
    render() {
        // const PostList = BlogContent.slice(0 , 3);

        return (
            <Fragment> 
            {/* <Helmet pageTitle="Main Demo" /> */}

            <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            {/* Start Slider Area   */}
            <div className="slider-wrapper" id="home">
                <SliderOne />
            </div>
            {/* End Slider Area   */}

            {/* Start About Area */}
            <div className="about-area ptb--120 bg_color--5" id="about">
                <About />
            </div>
            {/* End About Area */}

            {/* Start Service Area  */}
            <div className="service-area creative-service-wrapper ptb--120 bg_color--1" id="services">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-12">
                            <div className="section-title text-left mb--30">
                                <h2>HOW</h2>
                            </div>
                            <h3>We specialize in:</h3>
                        </div>
                    </div>
                    <div className="row creative-service">
                        <div className="col-lg-12">
                            <ServiceList item="6" column="col-lg-4 col-md-6 col-sm-6 col-12 text-left" />
                        </div>
                    </div>
                </div>
            </div>  
            {/* End Service Area  */} 

            {/* Start Portfolio Area */}
            {/* <div className="portfolio-area pt--120 pb--140 bg_color--1" id="works">
                <div className="rn-slick-dot">
                    <Portfolio />
                </div>
            </div> */}
            {/* End Portfolio Area */}

            {/* Start CounterUp Area */}
            <div className="rn-counterup-area pt--140 p pb--110 bg_color--5">
                <div className="container">
                    {/* <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title text-center">
                                <h3 className="fontWeight500">Our Fun Facts</h3>
                            </div>
                        </div>
                    </div> */}
                    <CounterOne />
                </div>
            </div>
            {/* End CounterUp Area */}

            {/* Start Testimonial Area */}
            {/* <div className="rn-testimonial-area bg_color--1 ptb--120" id="reviews">
                <div className="container">
                    <Testimonial />
                </div>
            </div> */}
            {/* End Testimonial Area */}

            {/* Start Brand Area */}
            {/* <div className="rn-brand-area brand-separation bg_color--5 pb--120">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <BrandTwo />
                        </div>
                    </div>
                </div>
            </div> */}
            {/* End Brand Area */}

            {/* Start Contact Us */}
            <div className="rn-contact-us ptb--120 bg_color--1" id="contact">
                <Contact />
            </div>
            {/* End Contact Us */}

            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}
            
            <Footer />
        </Fragment>
        )
    }
     
}
export default App;