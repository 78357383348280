import React ,{ Component }from "react";
import { FiCast , FiLayers , FiUsers , FiMonitor } from "react-icons/fi";

const ServiceList = [
    {
        icon: <FiCast />,
        title: 'SHOPIFY Development',
        description: 'We develop everything related to Shopify store from apps to small customizations.'
    },
    {
        icon: <FiLayers />,
        title: 'E-Commerce Development',
        description: 'We excel at developing custom user friendly and successful e-commerce websites.'
    },
    {
        icon: <FiUsers />,
        title: 'Websites/Apps Development',
        description: 'You got a bright website or app idea? We got you!'
    },
    { 
        icon: <FiMonitor />,
        title: 'WordPress Development',
        description: 'Our WordPress specialists can make your new website stand out from the competition.'
    },
    { 
        icon: <FiMonitor />,
        title: 'Web Design',
        description: 'We design user friendly UIs with a great UX for your future users to enjoy your new website.'
    },
    {
        icon: <FiUsers />,
        title: 'SEO and Speed Optimization',
        description: 'We offer SEO and speed optimization to make your website/app faster and better ranked on the web!'
    }
]


class ServiceThree extends Component{
    render(){
        const {column } = this.props;
        const ServiceContent = ServiceList.slice(0 , this.props.item);
        
        return(
            <React.Fragment>
                <div className="row">
                    {ServiceContent.map( (val , i) => (
                        <div className={`${column}`} key={i}>
                            {/* <a href="#"> */}
                                <div className="service service__style--2">
                                    <div className="icon">
                                        {val.icon}
                                    </div>
                                    <div className="content">
                                        <h3 className="title">{val.title}</h3>
                                        <p>{val.description}</p>
                                    </div>
                                </div>
                            {/* </a> */}
                        </div>
                    ))}
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceThree;
