import React from "react";
import { useForm } from "react-hook-form";
import * as emailjs from 'emailjs-com';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function ContactTwo() {
    
    const { register, handleSubmit, formState: { errors } } = useForm();

    const onSubmit = (data, e) => {
        emailjs.send('byte_coding', 'template_rfOd1uUi', data, 'user_J7qkTKmKiuAZN1jhMiF8o')
        .then(() => toast.success('Email Sent!'))
        .catch(() => toast.error('Error Sending the Email!'));
        e.target.reset();
    };

    return(
        <div className="contact-form--1">
            <div className="container">
                <div className="row row--35 align-items-start">
                    <div className="col-lg-6 order-2 order-lg-1">
                        <div className="section-title text-left mb--50">
                            <h2 className="title">START YOUR PROJECT</h2>
                            <p className="description">Let us help you get your project started.</p>
                        </div>
                        <div className="form-wrapper">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <label htmlFor="item01">
                                    {errors.from_name && <span className="contact-form-error">Name field is required</span>}
                                    <input
                                        {...register("from_name", { required: true })}
                                        type="text"
                                        id="item01"
                                        placeholder="Name *"
                                    />
                                </label>

                                <label htmlFor="item02">
                                    {errors.from_email && <span className="contact-form-error">Email field is required</span>}
                                    <input
                                        {...register("from_email", {required: true, pattern: /\S+@\S+\.\S+/})}
                                        type="text"
                                        id="item02"
                                        placeholder="Email *"
                                    />
                                </label>

                                <label htmlFor="item03">
                                    {errors.from_subject && <span className="contact-form-error">Subject field is required</span>}
                                    <input
                                        {...register("from_subject", { required: true })}
                                        type="text"
                                        id="item03"
                                        placeholder="Subject *"
                                    />
                                </label>
                                <label htmlFor="item04">
                                    {errors.message_html && <span className="contact-form-error">A message is required</span>}
                                    <textarea
                                        type="text"
                                        id="item04"
                                        {...register("message_html", { required: true })}
                                        placeholder="Message *"
                                    />
                                </label>
                                <button className="rn-button-style--2 btn-solid" type="submit" value="submit" name="submit" id="mc-embedded-subscribe">Submit</button>
                            </form>
                        </div>
                    </div>
                    <div className="col-lg-6 order-1 order-lg-2">
                        <div className="thumbnail mb_md--30 mb_sm--30 mt_sm--50">
                            <img src="/assets/images/contact/hello2.jpg" alt="contact"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}